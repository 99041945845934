import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LazyBackgroundImageLoader from 'components/Img/LazyBackgroundImageLoader';
import { media } from '../../theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  /* max-width: 284px; */
  display: flex;
  flex-direction: column;
  color: #343434;
  margin: 0 0 1rem;
`;

const Name = styled.h3`
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: #343434;
`;

const Summary = styled.h4`
  font-weight: 400;
  line-height: 17px;
  font-size: 0.75rem;
  color: #828183;
  margin: 0 0 0.25rem;

  ${media.forPhoneOnly`
  `}
`;

const Price = styled.span`
  line-height: 1.375rem;
  font-size: 0.875rem;
  margin-bottom: 0 0 5px;
`;

const Location = styled.span`
  line-height: 14px;
  font-size: 0.625rem;
  color: #828183;
  text-transform: capitalize;
`;

const Bookings = styled(Location)`
  color: #00b33b;
`;

const Divider = styled.div`
  width: 1px;
  height: 14px;
  margin: 0 0.25rem;
  background-color: #8c9baa;
`;

const Flex = styled.div`
  display: flex;
`;

const photoStyle = css`
  position: relative;
  height: 152px;
  border-radius: 2px;
  margin-bottom: 0.5rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const ItineraryCard = ({
  name,
  photo,
  placeCount,
  totalHours,
  days,
  priceFrom,
  currency,
  city,
  country,
  bookings
}) => {
  const dayStr = useMemo(() => {
    const _days = parseInt(days, 10);
    if (_days === 0) return '';

    return _days === 1 ? '1 Day' : `${_days} Days`;
  }, [days]);

  const hoursStr = useMemo(() => {
    const _days = parseInt(days, 10);
    const _totalHours = parseInt(totalHours, 10);
    if (_days > 1) return ''; // don't display hours when multiple days
    if (_totalHours === 0) return '';

    return _totalHours === 1 ? '・1 Hr' : `・${_totalHours} Hrs`;
  }, [totalHours, days]);

  const placeStr = useMemo(() => {
    const _placeCount = parseInt(placeCount, 10);
    if (_placeCount === 0) return '';

    return _placeCount === 1 ? '・1 Place' : `・${_placeCount} Places`;
  }, [placeCount]);

  return (
    <Root>
      <LazyBackgroundImageLoader
        height={152}
        offset={100}
        src={photo}
        imgStyle={photoStyle}
      />
      <Summary>{`${dayStr}${hoursStr}${placeStr}`}</Summary>
      <Name>{name}</Name>
      <Price>{`$${priceFrom} ${currency} per group`}</Price>
      <Flex>
        <Location>{city}</Location>
        <Location>・</Location>
        <Location>{country}</Location>
        <Divider />
        <Bookings>{`${bookings} Booked`}</Bookings>
      </Flex>
    </Root>
  );
};

ItineraryCard.propTypes = {
  name: PropTypes.string,
  placeCount: PropTypes.number,
  totalHours: PropTypes.number,
  days: PropTypes.number,
  priceFrom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  bookings: PropTypes.number,
  photo: PropTypes.string
};

ItineraryCard.defaultProps = {
  name: '',
  placeCount: 0,
  totalHours: 0,
  days: 0,
  priceFrom: 0,
  currency: 'TWD',
  city: '',
  country: '',
  bookings: 1,
  photo: ''
};

export default ItineraryCard;
